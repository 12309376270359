


import {
    Box,
    AppBar,
    Toolbar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Drawer,
    ListItemButton,
    MenuList,
    MenuItem,
    IconButton,
    Divider,
    Badge
} from "@mui/material";

import { Link, useHistory } from "react-router-dom";


import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import TourOutlinedIcon from '@mui/icons-material/TourOutlined';
import CommuteIcon from '@mui/icons-material/Commute';


import appLogo1 from '../../assets/images/app-logo-1.png';
import { useSelector } from "react-redux";

function SideBar({ drawerWidth }) {


    const tours = useSelector((state) => state.CMSAdminSideCounts.tours);
    const visas = useSelector((state) => state.CMSAdminSideCounts.visas);
    const hotels = useSelector((state) => state.CMSAdminSideCounts.rooms);
    const transports = useSelector((state) => state.CMSAdminSideCounts.transports);
    const bookings = useSelector((state) => state.CMSAdminSideCounts.bookings);
    const agencies = useSelector((state) => state.CMSAdminSideCounts.agencies);
    const products = useSelector((state) => state.CMSAdminSideCounts.products);




    return (
        <Drawer
            sx={{
                width: drawerWidth,
                maxWidth: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    border: 0,
                    boxShadow: 3
                },
            }}
            variant="permanent"
            anchor="left"
        >
            {/* <Toolbar /> */}
            <Link to='/' className='d-flex flex-row my-1' style={{ justifyContent: 'center' }} >
                <img src={appLogo1} width={80} height={60} />
            </Link>
            <Divider />

            <List style={{ overflow: 'hidden', overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none', margin: 0, padding: 0 }}>

                <Link to='/dashboard'>
                    <ListItem disablePadding className="p-1 py-1">
                        <ListItemButton className="flex-column" style={{ alignItems: 'center' }}>
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <DashboardOutlinedIcon />
                            </ListItemIcon>
                            <Typography className="text-black mt-1 text-center" style={{ fontSize: 'small' }} >Dashboard</Typography>
                        </ListItemButton>
                    </ListItem>
                </Link>


                <Link to='/agencies'>
                    <ListItem disablePadding className="p-1 py-1">
                        <ListItemButton className="flex-column" style={{ alignItems: 'center' }}>
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <Badge badgeContent={agencies.toString()} color="secondary" style={{}}>
                                    <CorporateFareOutlinedIcon />
                                </Badge>
                            </ListItemIcon>
                            <Typography className="text-black mt-1 text-center" style={{ fontSize: 'small' }} >Agencies</Typography>
                        </ListItemButton>
                    </ListItem>
                </Link>

                {/* <Link to='/dashboard'>
                    <ListItem disablePadding className="p-1 py-1">
                        <ListItemButton className="flex-column" style={{ alignItems: 'center' }}>
                            <ListItemIcon style={{ justifyContent: 'center' }}>

                                <GroupOutlinedIcon />
                            </ListItemIcon>
                            <Typography className="text-black mt-1 text-center" style={{ fontSize: 'small' }} >Admins</Typography>
                        </ListItemButton>
                    </ListItem>
                </Link> */}

                <Link to='/bookings'>
                    <ListItem disablePadding className="p-1 py-1">
                        <ListItemButton className="flex-column" style={{ alignItems: 'center' }}>
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <Badge badgeContent={bookings.toString()} color="info" style={{}}>
                                    <CollectionsBookmarkOutlinedIcon />
                                </Badge>
                            </ListItemIcon>
                            <Typography className="text-black mt-1 text-center" style={{ fontSize: 'small' }} >Bookings</Typography>
                        </ListItemButton>
                    </ListItem>
                </Link>

                <Link to='/tours'>
                    <ListItem disablePadding className="p-1 py-1">
                        <ListItemButton className="flex-column" style={{ alignItems: 'center' }}>
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <Badge badgeContent={tours.toString()} color="info" style={{}}>
                                    <TourOutlinedIcon />
                                </Badge>
                            </ListItemIcon>
                            <Typography className="text-black mt-1 text-center" style={{ fontSize: 'small' }} >Tours</Typography>
                        </ListItemButton>
                    </ListItem>
                </Link>


                <Link to='/visas'>
                    <ListItem disablePadding className="p-1 py-1">
                        <ListItemButton className="flex-column" style={{ alignItems: 'center' }}>
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <Badge badgeContent={visas.toString()} color="success" style={{}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="20">
                                        <path d="M0 64C0 28.7 28.7 0 64 0H384c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM183 278.8c-27.9-13.2-48.4-39.4-53.7-70.8h39.1c1.6 30.4 7.7 53.8 14.6 70.8zm41.3 9.2l-.3 0-.3 0c-2.4-3.5-5.7-8.9-9.1-16.5c-6-13.6-12.4-34.3-14.2-63.5h47.1c-1.8 29.2-8.1 49.9-14.2 63.5c-3.4 7.6-6.7 13-9.1 16.5zm40.7-9.2c6.8-17.1 12.9-40.4 14.6-70.8h39.1c-5.3 31.4-25.8 57.6-53.7 70.8zM279.6 176c-1.6-30.4-7.7-53.8-14.6-70.8c27.9 13.2 48.4 39.4 53.7 70.8H279.6zM223.7 96l.3 0 .3 0c2.4 3.5 5.7 8.9 9.1 16.5c6 13.6 12.4 34.3 14.2 63.5H200.5c1.8-29.2 8.1-49.9 14.2-63.5c3.4-7.6 6.7-13 9.1-16.5zM183 105.2c-6.8 17.1-12.9 40.4-14.6 70.8H129.3c5.3-31.4 25.8-57.6 53.7-70.8zM352 192A128 128 0 1 0 96 192a128 128 0 1 0 256 0zM112 384c-8.8 0-16 7.2-16 16s7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H112z" fill="#757575" />
                                    </svg>
                                </Badge>
                            </ListItemIcon>
                            <Typography className="text-black mt-1 text-center" style={{ fontSize: 'small' }} >Visas</Typography>
                        </ListItemButton>
                    </ListItem>
                </Link>


                <Link to='/hotels'>
                    <ListItem disablePadding className="p-1 py-1">
                        <ListItemButton className="flex-column" style={{ alignItems: 'center' }}>
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <Badge badgeContent={hotels.toString()} color="success" style={{}}>
                                    <HouseOutlinedIcon />
                                </Badge>
                            </ListItemIcon>
                            <Typography className="text-black mt-1 text-center" style={{ fontSize: 'small' }} >Hotels</Typography>
                        </ListItemButton>
                    </ListItem>
                </Link>

                <Link to='/transports'>
                    <ListItem disablePadding className="p-1 py-1">
                        <ListItemButton className="flex-column" style={{ alignItems: 'center' }}>
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <Badge badgeContent={transports.toString()} color="warning" style={{}}>
                                    <CommuteIcon />
                                </Badge>
                            </ListItemIcon>
                            <Typography className="text-black mt-1 text-center" style={{ fontSize: 'small' }} >Transports</Typography>
                        </ListItemButton>
                    </ListItem>
                </Link>


                {/* <Link to='/dashboard'>
                    <ListItem disablePadding className="p-1 py-1">
                        <ListItemButton className="flex-column" style={{ alignItems: 'center' }}>
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <SettingsOutlinedIcon />
                            </ListItemIcon>
                            <Typography className="text-black mt-1 text-center" style={{ fontSize: 'small' }} >Settings</Typography>
                        </ListItemButton>
                    </ListItem>
                </Link> */}

                <Link to='/store'>
                    <ListItem disablePadding className="p-1 py-1">
                        <ListItemButton className="flex-column" style={{ alignItems: 'center' }}>
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <Badge badgeContent={products.toString()} color="secondary" style={{}}>
                                    <AddShoppingCartIcon />
                                </Badge>
                            </ListItemIcon>
                            <Typography className="text-black mt-1 text-center" style={{ fontSize: 'small' }} >Store</Typography>
                        </ListItemButton>
                    </ListItem>
                </Link>

                <Link to='/module-settings'>
                    <ListItem disablePadding className="p-1 py-1">
                        <ListItemButton className="flex-column" style={{ alignItems: 'center' }}>
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <SettingsSuggestOutlinedIcon />
                            </ListItemIcon>
                            <Typography className="text-black mt-1 text-center" style={{ fontSize: 'small' }} >Module Settings</Typography>
                        </ListItemButton>
                    </ListItem>
                </Link>

            </List>
        </Drawer>
    );
}

export default SideBar;